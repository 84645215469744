import React from 'react'
import { Link } from 'gatsby';

export default () => (
  <nav
    style={{
      width: 200,
    }}
  >
    <Link to="/">Home</Link>
  </nav>
);
